import React from "react";
import SideBarVacantRooms from "../../Components/SideBarVacantRooms/SideBarVacantRooms";

const VacantRooms = () => {
  return (
    <>
      <SideBarVacantRooms />
    </>
  );
};

export default VacantRooms;
