import React from "react";
import SideBarPayRent from "../../Components/SideBarPayRent/SideBarPayRent";

const Payments = () => {
  return (
    <div>
      <SideBarPayRent />
    </div>
  );
};

export default Payments;
