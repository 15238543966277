import React from "react";
import "@fontsource/poppins/300.css";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import VacateComponent from "../VacateComponent/VacateComponent";
import "./VacateInitial.css";

const VacateInitial = () => {
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      <VacateComponent />
    </Box>
  );
};

export default VacateInitial;
