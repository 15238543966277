import React from "react";
import SideBarKplcBiller from "../../Components/SideBarKplcBiller/SideBarKplcBiller";
const KplcBiller = () => {
  return (
    <>
      <SideBarKplcBiller />
    </>
  );
};

export default KplcBiller;
