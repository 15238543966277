import React from "react";
import Login from "./Pages/Login/Login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Profile from "./Pages/Profile/Profile";
import TermsConstitution from "./Pages/TermsConstitution/TermsConstitution";
import Notifications from "./Pages/Notifications/Notifications";
import Repository from "./Pages/Repository/Repository";
import PayRent from "./Pages/PayRent/PayRent";
import MyPayments from "./Pages/MyPayments/MyPayments";
import KplcBiller from "./Pages/KplcBiller/KplcBiller";
import Vacate from "./Pages/Vacate/Vacate";
import VacantRooms from "./Pages/VacantRooms/VacantRooms";
import Notify from "./Pages/Notify/Notify";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/Notifications" element={<Notifications />} />
          <Route path="/Constitution" element={<TermsConstitution />} />
          <Route path="/Repository" element={<Repository />} />
          <Route path="/PayRent" element={<PayRent />} />
          <Route path="/MyPayMents" element={<MyPayments />} />
          <Route path="/KplcBiller" element={<KplcBiller />} />
          <Route path="/Vacate" element={<Vacate />} />
          <Route path="/VacantRooms" element={<VacantRooms />} />
          <Route path="/Notify" element={<Notify />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
