import React, { useState } from "react";
import "./PayRentInitial.css";
import "@fontsource/poppins/300.css";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import PaymentsBanner from "../../Assets/Images/pesapal.jpg";
import Form from "react-bootstrap/Form";
import axios from "axios";
const PaymentsInitial = () => {
  const [cardNotMpesa, setCardNotMpesa] = useState(false);
  const [amount, setAmount] = useState("");

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleButtonClick = async () => {
    if (!amount) {
      alert("Please enter an amount to pay.");
      return;
    }

    try {
      const response = await axios.post(
        "https://housingbackend.mathenoid.com/stkpush",
        { amount: amount } // Send amount as JSON object
      );
      console.log(response.data);
      // Optionally, handle the response if needed
    } catch (error) {
      console.error("Error during STK Push request:", error);
      // Optionally, handle the error if needed
    }
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      <div className="mainn">
        <div className="leftDiv">
          <div className="paymentMethod">
            <p className="sectionHeader">payment methods</p>
          </div>
          <div className="paymentMethods">
            <ul>
              <li className="buttonLi" onClick={() => setCardNotMpesa(true)}>
                Bank Card
              </li>
              <li className="buttonLi" onClick={() => setCardNotMpesa(false)}>
                Mpesa
              </li>
            </ul>
          </div>
        </div>
        <div className="centerDiv">
          <div className="paymentsBanner">
            <img
              src={PaymentsBanner}
              alt="payments banner"
              width="100%"
              height="60px"
            />
          </div>
          <div className="paymentsForm">
            {cardNotMpesa ? (
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Payment Amount</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter amount"
                    autoComplete="false"
                  />
                  <Form.Text className="text-muted">
                    We'll never share your input with anyone else.
                  </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Bank Card Number</Form.Label>
                  <Form.Control type="password" placeholder="card number" />
                  <Form.Text className="text-muted">
                    We'll never share your input with anyone else.
                  </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="Remember my input" />
                </Form.Group>
              </Form>
            ) : (
              <div>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Mpesa Amount</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter amount"
                      value={amount}
                      onChange={handleAmountChange}
                    />
                    <Form.Text className="text-muted">
                      We'll never share your number with anyone else.
                    </Form.Text>
                  </Form.Group>
                </Form>
              </div>
            )}
          </div>
          <div className="payBtn">
            <button
              onClick={handleButtonClick}
              className="btn btn-md btn-success"
            >
              {" "}
              Pay
            </button>
          </div>
        </div>
        <div className="rightDiv">
          <div className="paymentInformation">
            <p className="sectionHeader">payment for</p>
          </div>
          <div className="paymentFor">
            <Form.Select aria-label="Default select example">
              <option value="deposit">deposit</option>
              <option value="rent">rent</option>
            </Form.Select>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default PaymentsInitial;
