import React from "react";
import "./NotifyInitial.css";
import "@fontsource/poppins/300.css";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import NotifyComponent from "../NotifyComponent/NotifyComponent";

const NotifyInitial = () => {
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      <NotifyComponent />
    </Box>
  );
};

export default NotifyInitial;
