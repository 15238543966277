import * as React from "react";
import "./SideBarVacate.css";
import "@fontsource/poppins/300.css";
import Box from "@mui/material/Box";

import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Payment from "@mui/icons-material/Payment";
import KPLC from "@mui/icons-material/ElectricMeter";
import Notifications from "@mui/icons-material/Notifications";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import RoomIcon from "@mui/icons-material/Room";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Pic from "../../Assets/Images/person.png";
import Logout from "../LogoutContextMenu/LogoutContextMenu";
import { Link } from "react-router-dom";
import VacateInitial from "../VacateInitial/VacateInitial";
const drawerWidth = 190;

export default function SideBarVacate() {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "white",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="leftmenu">
            <img src={Pic} alt="" width={50} />
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                color: "rgb(105, 195, 123)",
                fontFamily: "poppins",
              }}
            >
              Mathenoid HOUSING{" "}
            </Typography>
          </div>

          <div className="rightmenu">
            <Logout />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            color: "white",
          },
        }}
      >
        <Toolbar />
        <Box
          sx={{
            overflow: "auto",
            backgroundColor: "#69c37b",
            height: "100dvh",
          }}
        >
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>{<DashboardIcon />}</ListItemIcon>
              <Link className="linkNoDecoration" to="/Dashboard">
                <ListItemText primary="Dashboard" />
              </Link>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>{<Payment />}</ListItemIcon>
              <Link className="linkNoDecoration" to="/PayRent">
                <ListItemText primary="Pay Rent" />
              </Link>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>{<AccountBalanceIcon />}</ListItemIcon>
              <Link className="linkNoDecoration" to="/MyPayments">
                <ListItemText primary="My Payments" />
              </Link>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>{<KPLC />}</ListItemIcon>
              <Link className="linkNoDecoration" to="/KplcBiller">
                <ListItemText primary="KPLC Biller" />
              </Link>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>{<Notifications />}</ListItemIcon>
              <Link className="linkNoDecoration" to="/Notifications">
                <ListItemText primary="Notifications" />
              </Link>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>{<LocalShippingIcon />}</ListItemIcon>
              <Link className="linkNoDecoration" to="/Vacate">
                <ListItemText primary="Vacate" />
              </Link>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>{<RoomIcon />}</ListItemIcon>
              <Link className="linkNoDecoration" to="/VacantRooms">
                <ListItemText primary="VacantRooms" />
              </Link>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>{<ThumbsUpDownIcon />}</ListItemIcon>
              <Link className="linkNoDecoration" to="/Notify">
                <ListItemText primary="Notify" />
              </Link>
            </ListItemButton>
          </ListItem>
          <Divider />
          <br />
          <div
            style={{
              display: "flex",
              width: drawerWidth * 0.95,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <a
              href="https://Mathenoid.com"
              target="_blank"
              rel="noreferrer"
              className="owner"
            >
              Mathenoidlabs &copy; 2024
            </a>
          </div>
        </Box>
      </Drawer>
      <VacateInitial />
    </Box>
  );
}
