import React, { useState } from "react";
import { Table } from "react-bootstrap";
import "./NotificationsTable.css";

const NotificationsTable = () => {
  const [notifications, setNotifications] = useState([
    {
      title: "Rent Deadline",
      description: "Please adhere to the rent settlement deadline.",
      date: "2024-07-01",
      from: "land owner",
    },
    {
      title: "KPLC Notice",
      description: "Please do settle your electricity bill before end day",
      date: "2024-07-03",
      from: "caretaker",
    },
    {
      title: "Laundry",
      description:
        "You are hereby notified that washing of clothes in the bathroom is prohibited and actionable",
      date: "2024-07-07",
      from: "land owner",
    },
  ]);

  return (
    <div className="container mt-5">
      <h2 className="mb-4">Notifications</h2>
      <div className="table-container">
        <Table className="table">
          <thead>
            <tr>
              <th>Serial</th>
              <th>From</th>
              <th>Title</th>
              <th>Description</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {notifications.map((notification, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{notification.from}</td>
                <td>{notification.title}</td>
                <td>{notification.description}</td>
                <td>{notification.date}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default NotificationsTable;
