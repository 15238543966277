import React from "react";
import "@fontsource/poppins/300.css";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import "./DashboardInitial.css";
import Card from "../../Components/Card/Card";
import DP from "../../Assets/Images/person.png";
import CalendarEvents from "../../Components/CalendarEvents/CalendarEvents";
import { Typography } from "@mui/material";

const DashboardInitial = () => {
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      <div className="dashboardContents">
        <div className="leftDashboardContents">
          <div className="name">Dashboard</div>
          <div className="welcome">
            <div className="welcomeRight">
              <span className="title">Welcome back nyagah mbugua</span>
              <p className="welcomeText">
                We're delighted to have you. Need help on system walk through?
                Navigate to virtual assistant on the side menu
              </p>
            </div>
            <div className="welcomeImg">
              <img src={DP} alt="nyagah" width="70px" height="70px" />
            </div>
          </div>
          <div className="eventsBox">
            <div className="eventTitleCalendar">
              <div className="eventTitle">
                <p className="title">Events</p>
              </div>
              <div className="calendar">
                <CalendarEvents />
              </div>
            </div>

            <div className="key">
              <div className="keyElement">💡 Electricity deadline</div>
              <div className="keyElement">💶 Rent deadline</div>
              <div className="keyElement">🧑‍🎄 Holiday </div>
            </div>
          </div>
        </div>
        <div className="rightDashboardContents">
          <Card name="Arrears" number="KSh 2,000" />
          <Card name="Vacant Rooms" number="7" />
          <Card name="Notifications" number="5" />
        </div>
      </div>
      <Typography
        variant="h6"
        noWrap
        component="div"
        sx={{
          color: "#69c37b",
          fontWeight: "bolder",
          fontSize: "15px",
        }}
      >
        Welcome kelvin nyagah mbugua
      </Typography>{" "}
    </Box>
  );
};

export default DashboardInitial;
