import React from "react";
import "@fontsource/poppins/300.css";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MyPaymentsTable from "../MyPaymentsTable/MyPaymentsTable";
import "./MyPaymentsInitial.css";

const MyPaymentsInitial = () => {
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div className="paymentsInitialMainDiv">
        <Toolbar />
        <MyPaymentsTable />{" "}
      </div>
    </Box>
  );
};

export default MyPaymentsInitial;
