import React from "react";
import "./MyPaymentsTable.css";

const PaymentsTable = () => {
  const payments = [
    { date: "2024-06-01", amount: "KSh 100.00", reference: "ABC123" },
    { date: "2024-06-02", amount: "KSh 200.00", reference: "DEF456" },
    { date: "2024-06-03", amount: "KSh 300.00", reference: "GHI789" },
    // Add more rows as needed
  ];

  return (
    <div className="table-responsive">
      <table className="table table-striped table-hover">
        <thead className="thead-dark">
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Amount</th>
            <th scope="col">Reference</th>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment, index) => (
            <tr key={index}>
              <td>{payment.date}</td>
              <td>{payment.amount}</td>
              <td>{payment.reference}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentsTable;
