import React, { useState } from "react";
import {
  Button,
  Popover,
  OverlayTrigger,
  Form,
  Table,
  Modal,
} from "react-bootstrap";

const VacatePage = () => {
  const [showPopover, setShowPopover] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [moveOutMonth, setMoveOutMonth] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [showVacateButton, setShowVacateButton] = useState(true);

  const handleVacateClick = () => {
    setShowPopover(true);
  };

  const handleMonthSelect = (e) => {
    setMoveOutMonth(e.target.value);
    setShowConfirm(true);
    setShowPopover(false);
  };

  const handleConfirm = () => {
    setShowTable(true);
    setShowConfirm(false);
    setShowVacateButton(false);
  };

  const handleCancel = () => {
    setShowConfirm(false);
    setShowPopover(false);
  };

  const handleRevoke = () => {
    setShowTable(false);
    setShowVacateButton(true);
  };

  const vacatePopover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Select Move Out Month</Popover.Header>
      <Popover.Body>
        <Form.Select aria-label="Select month" onChange={handleMonthSelect}>
          <option>Select Month</option>
          <option value="January">January</option>
          <option value="February">February</option>
          <option value="March">March</option>
          <option value="April">April</option>
          <option value="May">May</option>
          <option value="June">June</option>
          <option value="July">July</option>
          <option value="August">August</option>
          <option value="September">September</option>
          <option value="October">October</option>
          <option value="November">November</option>
          <option value="December">December</option>
        </Form.Select>
      </Popover.Body>
    </Popover>
  );

  return (
    <div className="container mt-5">
      {showVacateButton && (
        <OverlayTrigger
          trigger="click"
          placement="right"
          overlay={vacatePopover}
          show={showPopover}
        >
          <Button variant="primary" onClick={handleVacateClick}>
            Vacate
          </Button>
        </OverlayTrigger>
      )}

      <Modal className="mt-5" show={showConfirm} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Move Out</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to move out in {moveOutMonth}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {showTable && (
        <>
          <Table striped bordered hover className="mt-5">
            <thead>
              <tr>
                <th>Name</th>
                <th>Room</th>
                <th>Date</th>
                <th>Move Out Month</th>
                <th>Arrears</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Nyagah</td>
                <td>3B</td>
                <td>22/06/2024</td>
                <td>{moveOutMonth}</td>
                <td>0</td>
              </tr>
            </tbody>
          </Table>
          <Button variant="danger" className="mt-3" onClick={handleRevoke}>
            Revoke Moveout
          </Button>
        </>
      )}
    </div>
  );
};

export default VacatePage;
