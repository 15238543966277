import React, { useState } from "react";
import { Table, Container, Button } from "react-bootstrap";

const VacantRoomsTable = () => {
  const initialRooms = [
    { unit: 3, room: "3D", floor: "1st", rent: "KSh 4500" },
    { unit: 6, room: "6A", floor: "1st", rent: "KSh 4500" },
    { unit: 8, room: "8B", floor: "3rd", rent: "KSh 4000" },
  ];

  const [rooms] = useState(initialRooms);
  const [bookedRoom, setBookedRoom] = useState(null);

  const handleBook = (room) => {
    setBookedRoom(room);
  };

  const handleUnbook = () => {
    setBookedRoom(null);
  };

  return (
    <Container className="mt-5">
      <h2 className="text-center mb-4">Vacant Rooms</h2>
      <Table responsive className="table-sm elegant-table">
        <thead className="thead-dark">
          <tr>
            <th>Unit</th>
            <th>Room</th>
            <th>Rent</th>
            <th>Floor</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {rooms.map((vacantRoom, index) => (
            <tr key={index}>
              <td>{vacantRoom.unit}</td>
              <td>{vacantRoom.room}</td>
              <td>{vacantRoom.rent}</td>
              <td>{vacantRoom.floor}</td>
              <td>
                {bookedRoom === vacantRoom.room ? (
                  <Button variant="danger" onClick={handleUnbook}>
                    Unbook
                  </Button>
                ) : (
                  <Button
                    variant="primary"
                    onClick={() => handleBook(vacantRoom.room)}
                    disabled={bookedRoom !== null}
                  >
                    Book
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default VacantRoomsTable;
