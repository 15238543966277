import React from "react";
import "@fontsource/poppins/300.css";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import "./KplcBillerInitial.css";

const KplcBillerInitial = () => {
  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Toolbar />
      <div className="kplcMainDiv">
        <iframe
          src="https://selfservice.kplc.co.ke/"
          title="kplc bil checker"
          frameborder="0"
          className="kplcWindow"
        ></iframe>
        <div className="accountNo">Account Number: 27702261</div>
      </div>
    </Box>
  );
};

export default KplcBillerInitial;
