import React from "react";
import SideBarVacate from "../../Components/SideBarVacate/SideBarVacate";

const Vacate = () => {
  return (
    <>
      <SideBarVacate />
    </>
  );
};

export default Vacate;
