import React, { useState } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

const Repository = () => {
  const [items, setItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({ date: "", item: "" });
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, item: file });
  };

  const handleAddItem = () => {
    setItems([
      ...items,
      {
        ...formData,
        serial: items.length + 1,
        url: URL.createObjectURL(formData.item),
      },
    ]);
    setFormData({ date: "", item: "" });
    handleCloseModal();
  };

  const handleShare = () => {
    setShowSnackbar(true);
    setTimeout(() => setShowSnackbar(false), 5000);
  };

  const isFormValid = formData.date && formData.item;

  return (
    <div className="container mt-4">
      <Table striped hover>
        <thead>
          <tr>
            <th>Serial</th>
            <th>Date</th>
            <th>Item</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={index}>
              <td>{item.serial}</td>
              <td>{item.date}</td>
              <td>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip>Download {item.item.name}</Tooltip>}
                >
                  <a
                    href={item.url}
                    download={item.item.name}
                    className="text-decoration-none"
                  >
                    {item.item.name}
                  </a>
                </OverlayTrigger>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Button
        variant="success"
        className="position-fixed"
        style={{ right: "20px", bottom: "20px" }}
        onClick={handleShowModal}
      >
        Add Item
      </Button>

      <Modal className="mt-5" show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add New Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formDate">
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formItem">
              <Form.Label>Item</Form.Label>
              <Form.Control
                type="file"
                name="item"
                onChange={handleFileChange}
              />
            </Form.Group>
            <Button
              variant="primary"
              disabled={!isFormValid}
              onClick={handleAddItem}
            >
              Add Item
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Repository;
