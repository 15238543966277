import React from "react";
import SideBarMyPayments from "../../Components/SideBarMyPayments/SideBarMyPayments";

const MyPayments = () => {
  return (
    <div>
      <SideBarMyPayments />
    </div>
  );
};

export default MyPayments;
